import * as React from 'react';
import AppProvider from './src/common/context/AppContext';
import { Helmet } from 'react-helmet';

export const wrapRootElement = ({ element }) => (
  <div className="tk-acumin-pro">
    <Helmet>
      <link rel="stylesheet" href="https://use.typekit.net/dya7upi.css" />
    </Helmet>
    <AppProvider>{element}</AppProvider>
  </div>
);
