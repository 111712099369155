import React, { CSSProperties } from 'react';
import { useMVT } from '@lam-agency/toolkit/stores';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { parseImagesFromFigma } from '@lam-agency/toolkit/utils/figma';
import {
  ATFWidget,
  LayoutProvider,
  TAnimation,
  TextAnimation
} from '@lam-agency/toolkit/components';
import { useScroll } from '@lam-agency/toolkit/hooks';
import { getCSSVariableValue } from '@lam-agency/toolkit/utils';
import Draggable from 'react-draggable';
import { IWidgetProps } from '@lam-agency/toolkit/components/Common/ATFWidget';

interface IFields extends IWidgetProps {
  body: string;
  images: string[];
}
interface IProps {
  data: {
    fields: IFields;
  };
}

const FigmaCopyOnImage = ({
  data: {
    fields: { assetLinks, body, images, contactURL, quickLinks }
  }
}: IProps) => {
  const { projectString } = useMVT();
  const { scrollY } = useScroll();

  const allImages = parseImagesFromFigma(projectString, images);

  const amountToScrollBeforeBlurPx = 300; // sync with amoutToScrollBeforeFill in Header.tsx
  const blurMultiplier = 0.02;
  const normalisedScroll = Math.max(0, scrollY - amountToScrollBeforeBlurPx);
  const blurAmountPx = normalisedScroll * blurMultiplier;

  const darkenMultiplier = 0.002;
  const maxDarkenOpacity = 0.75;
  const darkenOpacity = Math.min(
    maxDarkenOpacity,
    normalisedScroll * darkenMultiplier
  );

  const imageScaleMultiplier = 0.0002;
  const maxScale = 1.1;
  const minScale = 1;

  const imageScale = Math.max(
    minScale,
    maxScale - normalisedScroll * imageScaleMultiplier
  );

  const textAnimation = getCSSVariableValue(
    '--atf-text-animation'
  ) as TAnimation;

  return (
    <>
      <div className={styles.container}>
        {/* todo: how would we know which image to use? */}
        {allImages?.[0]?.image && (
          <img
            aria-hidden
            className={styles.background}
            src={allImages[0].image}
            alt=""
            style={{ transform: `scale(${imageScale})` }}
          />
        )}

        <LayoutProvider grid paddingX paddingY className={styles.layout}>
          <div className={styles.textWrapper}>
            <TextAnimation
              className={cn('d1')}
              text={body}
              animation={textAnimation}
              speed={0.03}
            />
          </div>

          <ATFWidget
            contactURL={contactURL}
            assetLinks={assetLinks}
            quickLinks={quickLinks}
          />
        </LayoutProvider>

        <Stickers scrollY={scrollY} />

        <div
          aria-hidden
          style={{
            backdropFilter: `blur(${blurAmountPx}px)`,
            backgroundColor: `rgba(0, 0, 0, ${darkenOpacity})`
          }}
          className={styles.blur}
        />
      </div>
      <div aria-hidden className={styles.scrollPadding} />
    </>
  );
};

export default FigmaCopyOnImage;

const Sticker = ({
  src,
  left,
  rotation,
  top,
  index
}: {
  src: string;
  top: string;
  left: string;
  rotation: string;
  index: number;
}) => {
  const delayStaggerMS = 50;

  return (
    <Draggable>
      <div className={styles.stickerContainer} style={{ top, left }}>
        <div
          style={{ animationDelay: `${index * delayStaggerMS}ms` }}
          className={styles.stickerAnimation}
        >
          <img
            draggable={false}
            style={{ transform: `rotate(${rotation})` }}
            aria-hidden
            src={src}
            alt=""
          />
        </div>
      </div>
    </Draggable>
  );
};

const Stickers = ({ scrollY }: { scrollY: number }) => {
  const scrollTransformMultiplier = 0.07;
  const translateY = -scrollY * scrollTransformMultiplier;

  const containerStyles: CSSProperties = {
    transform: `translateY(${translateY}px)`
  };

  return (
    <div style={containerStyles} className={styles.stickersContainer}>
      <div className={styles.stickersRelative}>
        <Sticker
          src="/images/sticker-1.svg"
          left="5%"
          rotation="20deg"
          top="10%"
          index={1}
        />
        <Sticker
          src="/images/sticker-4.svg"
          left="25%"
          rotation="345deg"
          top="40%"
          index={2}
        />
        <Sticker
          src="/images/sticker-2.svg"
          left="45%"
          rotation="30deg"
          top="20%"
          index={3}
        />
        <Sticker
          src="/images/sticker-5.svg"
          left="50%"
          rotation="350deg"
          top="50%"
          index={4}
        />
        <Sticker
          src="/images/sticker-3.svg"
          left="70%"
          rotation="5deg"
          top="80%"
          index={5}
        />
        <Sticker
          src="/images/sticker-6.svg"
          left="80%"
          rotation="15deg"
          top="50%"
          index={6}
        />
      </div>
    </div>
  );
};
